import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Alert, Result } from "antd";
import * as Sentry from "@sentry/react";
import { TOKEN_RESTRICTED_PATH } from "./constants/common";
import Login from "./pages/auth/login";
import AdminLayout from "./pages/AdminLayout";
import UserManagement from "./pages/UserManagement/index";
import AppointmentList from "./pages/AppointmentList/index";
import NotFound from "./pages/NotFound.js";
import Notifications from "./pages/Notifications/index";
import "./App.less";
import OrganizationList from "./pages/OrganizationList";
import SubMasters from "./pages/SubMaster";
import PrimeHealth from "./pages/PrimeHealth";
import { Setting } from "./pages/OrganizationList/Setting";
import GoogleMyBusiness from "./pages/googleMybusinessLocations";
import AddSteps from "./pages/OrganizationList/AddSteps";
import CommonConfiguration from "./pages/CommonConfiguration";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import ERListing from "./pages/ErDirectory/listing/index.jsx";

Sentry.init({
  dsn: "https://b026d31b1bd044678755c82426d40d12@o4505145591791616.ingest.sentry.io/4505400311414784",
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  const { isLoading, error, isAuthenticated, user } = useAuth0();
  let basePath = window.location.pathname;
  basePath = basePath.split("/");
  if (error) {
    return (
      <Alert
        message="Error"
        description={error.message}
        type="error"
        showIcon
      />
    );
  }

  if (isLoading) {
    return (
      <div class="loader loading">
        <img src="/loader.svg" alt="loader" />
      </div>
    );
  }

  if (
    TOKEN_RESTRICTED_PATH.indexOf(basePath[1]) > -1 &&
    window.location.search.indexOf("689c7146-b1b3-4dd8-bdeb-40836ddb1403") ===
      -1
  ) {
    return (
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
      />
    );
  }
  return (
    <Router history={history}>
      <Switch>
        {!isAuthenticated && <Route exact path="/" component={Login} />}
        {!isAuthenticated && <Route exact path="/login" component={Login} />}

        <Route
          component={() => {
            // if (!isAuthenticated) {
            //   history.push("/404")
            //   return null
            // }
            return (
              <AdminLayout>
                <div>
                  <Route exact path="/" component={UserManagement} />

                  <Route
                    exact
                    path="/app/user-management"
                    component={UserManagement}
                  />
                  <Route
                    exact
                    path="/app/patient-notifications"
                    component={Notifications}
                  />
                  <Route
                    exact
                    path="/app/patient-appointments"
                    component={AppointmentList}
                  />
                  <Route
                    exact
                    path="/app/organizations"
                    component={OrganizationList}
                  />
                  <Route
                    exact
                    path="/app/organizations/add"
                    component={AddSteps}
                  />
                  <Route exact path="/app/er-directory" component={ERListing} />
                  <Route exact path="/app/:id/settings" component={Setting} />

                  <Route exact path="/app/sub-masters" component={SubMasters} />
                  <Route
                    exact
                    path="/app/prime-health"
                    component={PrimeHealth}
                  />
                  <Route
                    exact
                    path="/app/google-my-business"
                    component={GoogleMyBusiness}
                  />
                  <Route
                    exact
                    path="/app/common-configuration"
                    component={CommonConfiguration}
                  />
                </div>
              </AdminLayout>
            );
          }}
        />
        <Route path="/404" component={NotFound} />
      </Switch>
    </Router>
  );
}
export default App;
